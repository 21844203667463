var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { column: "" } },
    [
      _c(
        "v-col",
        {
          class: {
            "d-flex": true,
            "justify-start": _vm.horizontalOrientation === "left",
            "justify-center": _vm.horizontalOrientation === "center",
            "justify-end": _vm.horizontalOrientation === "right",
          },
          style: {
            "padding-left": _vm.horizontalPadding,
            "padding-right": _vm.horizontalPadding,
            "padding-top": _vm.verticalPadding,
            "padding-bottom": _vm.verticalPadding,
            width: _vm.horizontalOrientation === "stretch" ? "100%" : undefined,
          },
          attrs: { cols: 12 },
        },
        [
          _c(
            "div",
            {
              class: {
                "align-self-start": _vm.verticalOrientation === "top",
                "align-self-center": _vm.verticalOrientation === "center",
                "align-self-end": _vm.verticalOrientation === "bottom",
                "align-self-stretch": _vm.verticalOrientation === "stretch",
              },
              style: {
                width:
                  _vm.horizontalOrientation === "stretch" ? "100%" : undefined,
              },
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }