var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Chart", {
    attrs: {
      width: _vm.width,
      height: _vm.height,
      series: _vm.series,
      type: "bar",
      tooltipEnabled: false,
      horizontal: true,
      lablesEnabled: false,
      yAxisTitle: _vm.$t(
        "statistics.brandsByNiceClassBarChart.niceClassesAxisLabel"
      ),
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }