var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-main", [
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-us" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("US"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.us.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.us.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticUsOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticUsLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.us.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticUsOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.us.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.us.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticUsLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.us.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.us.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticUsLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticUsLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-de" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("DE"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.de.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.de.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticDeOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticDeLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.de.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticDeOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.de.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.de.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticDeLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.de.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.de.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticDeLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticDeLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-at" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("AT"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.at.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.at.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticAtOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticAtLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.at.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticAtOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.at.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.at.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticAtLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.at.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.at.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticAtLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticAtLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-ch" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("CH"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.ch.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.ch.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticChOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticChLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.ch.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticChOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.ch.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.ch.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticChLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.ch.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.ch.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticChLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticChLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-es" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("ES"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.es.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.es.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticEsOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticEsLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.es.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticEsOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.es.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.es.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticEsLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.es.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.es.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticEsLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticEsLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-fr" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("FR"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.fr.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.fr.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticFrOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticFrLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.fr.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticFrOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.fr.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.fr.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticFrLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.fr.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.fr.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticFrLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticFrLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
    _c(
      "section",
      {
        staticClass: "theme--light default-vertical-padding",
        attrs: { id: "brands-em" },
      },
      [
        _c(
          "v-container",
          { attrs: { fluid: "" } },
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "v-layout",
                      {
                        staticClass: "headline",
                        attrs: {
                          row: "",
                          "justify-center": "",
                          "data-aos": "fade-down",
                        },
                      },
                      [
                        _c("v-img", {
                          staticClass: "mr-3",
                          attrs: {
                            src: _vm.$braendz.registrationOfficeCodeFlag("EM"),
                            contain: "",
                            "max-height": "32",
                            "max-width": "32",
                          },
                        }),
                        _c("h1", { staticClass: "title" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("insights.em.headline")) + " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pt-6",
                        attrs: {
                          horizontalOrientation: "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center",
                            attrs: { "data-aos": "fade-down" },
                          },
                          [
                            _c("p", {
                              staticClass: "description",
                              staticStyle: { "font-size": "20px" },
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$t("insights.em.introduction")
                                ),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c("TwoColumns", {
                      staticClass: "pb-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticEmOverall.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsOverall"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("TotalBrandsBox", {
                                attrs: {
                                  data: _vm.statisticEmLastMonth.object,
                                  icon: "fa-light fa-globe",
                                  label: _vm.$t(
                                    "insights.counter.brandsNew30d"
                                  ),
                                  iconColor: "tertiary",
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("insights.em.titleNiceClassDistribution")
                            )
                          ),
                        ]),
                      ]
                    ),
                    _c(
                      "OneColumn",
                      {
                        staticClass: "pb-6",
                        attrs: {
                          horizontalOrientation: _vm.$vuetify.breakpoint
                            .smAndDown
                            ? "stretch"
                            : "center",
                          verticalOrientation: "center",
                        },
                      },
                      [
                        _c("BrandsByNiceClassBarChart", {
                          attrs: {
                            data: _vm.statisticEmOverall.object,
                            width: _vm.$vuetify.breakpoint.smAndDown
                              ? "100%"
                              : "800",
                            height: 800,
                            showKeysOnly: _vm.$vuetify.breakpoint.smAndDown,
                            "data-aos": "fade-down",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("TwoColumns", {
                      staticClass: "py-6",
                      attrs: {
                        midPadding: "100px",
                        verticalOrientationFirst: "center",
                        verticalOrientationSecond: "center",
                        horizontalOrientationFirst: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "right",
                        horizontalOrientationSecond: _vm.$vuetify.breakpoint
                          .smAndDown
                          ? "center"
                          : "left",
                        breakOn: "sm",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "first",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  class: {
                                    "text-center":
                                      _vm.$vuetify.breakpoint.smAndDown,
                                  },
                                  staticStyle: { "max-width": "600px" },
                                  attrs: { "data-aos": "fade-down" },
                                },
                                [
                                  _c(
                                    "h1",
                                    { staticStyle: { "font-size": "25px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "insights.em.titleTypeDistribution"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _c("p", {
                                    staticClass: "description",
                                    staticStyle: { "font-size": "20px" },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t(
                                          "insights.em.descriptionTypeDistribution"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "second",
                          fn: function () {
                            return [
                              _c("BrandsByBrandTypeDonutChart", {
                                attrs: {
                                  labelTotalEnabled: false,
                                  data: _vm.statisticEmLastMonth.object,
                                  width: "400",
                                  legendPosition: "bottom",
                                  responsiveBreakpoint:
                                    _vm.$vuetify.breakpoint.thresholds.md,
                                  "data-aos": "fade-down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "text-center justify-center pt-16",
                        attrs: { "data-aos": "fade-down" },
                      },
                      [
                        _c("h1", { staticStyle: { "font-size": "25px" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("insights.em.titleLatestBrands"))
                          ),
                        ]),
                        _c("p", {
                          staticClass: "description",
                          staticStyle: { "font-size": "20px" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("insights.em.descriptionLatestBrands", {
                                count: _vm.$n(
                                  _vm.$statistics.getBrandTotalOrZero(
                                    _vm.statisticEmLastMonth.object
                                  )
                                ),
                              })
                            ),
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("BrandThumbnailsCarousel", {
          attrs: {
            data: _vm.statisticEmLastMonth.object,
            "data-aos": "fade-down",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }